.upload-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.select-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

label {
  margin-right: 10px;
}

.select-container button {
  margin-left: 10px;
}

.data-table table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th,
td {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
}

.data-table th {
  background-color: #3498db;
  color: white;
}
