.schedule-table {
  width: 90%;
  border-collapse: collapse;
  overflow-x: scroll;
}

.schedule-table td {
  padding: 1vw; /* Adjust padding to be relative to the viewport width */
  border: 1px solid #ccc;
  text-align: center;
}

.schedule-table th {
  padding: 1vw; /* Adjust padding to be relative to the viewport width */
  border: 1px solid #ccc;
  text-align: center;
  min-width: 10vw;
}

/* Customize the styles for the content inside the cells */
.cell-content {
  min-height: 8vh; /* Adjust min-height to be relative to the viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subject {
  font-weight: bold;
  font-size: 1.1vw; /* Adjust font size to be relative to the viewport width */
  padding: 0.3vw 0.6vw; /* Adjust padding to be relative to the viewport width */
  background-color: black;
  border-radius: 4px;
}
.timetableHeader {
  font-weight: bold;
  font-size: 1.1vw; /* Adjust font size to be relative to the viewport width */
  color: black;
}


.room {
  font-size: 0.8vw; /* Adjust font size to be relative to the viewport width */
  margin-top: 0.5vh; /* Adjust margin-top to be relative to the viewport height */
  background-color: #f4f0f0; /* Customize badge background color */
  padding: 0.2vw 0.6vw; /* Adjust padding to be relative to the viewport width */
  border-radius: 4px;
  color: black;
}
.homeroomClass {
  background-color: red;
  color: white;
  font-size: 1.3vw;
}
.teacher {
  font-size: 0.8vw; /* Adjust font size to be relative to the viewport width */
  margin-top: 0.5vh; /* Adjust margin-top to be relative to the viewport height */
  background-color: rgb(213, 232, 41);
  padding: 0.2vw 0.6vw; /* Adjust padding to be relative to the viewport width */
  border-radius: 4px;
  color: rgb(12, 12, 12);
  font-weight: bolder;
}
.homeroomTeacher {
  color: blue;
  font-weight: bolder;
  font-size: 1.1vw; /* Adjust font size to be relative to the viewport width */
}
/* Modal Overlay */
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Modal Content */
.modal-content {
  height: 70vh;  /* Fixes modal height to 70% of the viewport */
  overflow-y: auto; /* Ensures scrolling if content exceeds modal height */
  padding: 15px;
}

/* Adjust Dialog Box */
.MuiDialog-paper {
  height: 75vh !important; /* Ensures the modal itself is also 70-75% of viewport */
  max-height: 75vh !important;
  width: 80%; /* Adjust width for better visibility */
  max-width: 900px; /* Prevents it from becoming too wide */
  border-radius: 12px; /* Smooth edges */
}

/* Table Wrapper */
.table-wrapper {
  overflow-x: auto;
  max-height: 100%; /* Ensures table respects modal height */
}

/* Styled Table */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Table Headers */
.styled-table th {
  background-color: #007bff;
  color: white;
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

/* Table Rows */
.styled-table td {
  padding: 12px;
  border: 1px solid #ddd;
}

/* Hover Effect */
.table-row:hover {
  background-color: #f1f1f1;
}

/* Close Button */
.modal-container button {
  margin-top: 20px;
}
