.multSelect {
  width: 300px;
  overflow: scroll;
  text-align: center;
}
.exceeding {
  background-color: lightgreen;
  padding: 10px;
  border-radius: 25%;
  -webkit-border-radius: 25%;
  -moz-border-radius: 25%;
  -ms-border-radius: 25%;
  -o-border-radius: 25%;
}
.meeting {
  background-color: orange;
  padding: 10px;
  border-radius: 25%;
  -webkit-border-radius: 25%;
  -moz-border-radius: 25%;
  -ms-border-radius: 25%;
  -o-border-radius: 25%;
}
.below {
  background-color: red;
  padding: 10px;
  border-radius: 25%;
  -webkit-border-radius: 25%;
  -moz-border-radius: 25%;
  -ms-border-radius: 25%;
  -o-border-radius: 25%;
}
.wider {
  font-weight: bolder;
  font-size: larger;
  width: 100px;
}

.reportTable td {
  font-size: large;
  padding: 5px;
  font-weight: lighter;
  border: white inset;
}
.reportTable th {
  padding: 0px;
  border-collapse: collapse;
}
.reportTable {
  border-collapse: collapse;
}
/* .progressrepotSelect{
margin-top: 32vh;
} */
/* .progressSelect{
  border: none;
} */
.editabletablecell {
  color: rgb(19, 124, 236);
  cursor: pointer;
  border: solid outset;
  user-select: none;
  width: 10px;
}
