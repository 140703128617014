.navAttactive {
  background-color: rgb(132, 132, 184);
  color: white;
  user-select: none;
  padding: 0.5%;
  cursor: pointer;
}
.navAtt {
  user-select: none;
  cursor: pointer;
  padding: 0.5%;
}
#table-to-xls {
  margin-top: 2%;
}
.selectClassDate {
  margin-top: 2%;
}
.byStudentDiv {
  margin-top: 2%;
}
.inputField {
  /* style={{ margin: "0 20px", width: "30%", height: "40px" }} */
  margin: 0 20px;
  width: 30%;
  height: 40px;
}
.present {
  color: green;
  background-color: aquamarine;
}
.absent {
  color: red;
  background-color: pink;
}
/* @media print{
  .A_ ,.L,.P,.x{
    visibility: hidden;
  }
} */
.A_ {
  color: red;
  background-color: red;
  font-weight: bold;
}
.L {
  color: orange;
  font-weight: bold;
}
.P {
  color: green;
  font-weight: bold;
}
.x {
  background-color: rgb(241, 233, 233);
  color: white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
#containerStAtt {
  border: solid black;
}
