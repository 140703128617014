.students-at-risk-container {
  width: 90%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.students-header {
  text-align: center;
  color: #333;
  font-size: 24px;
}

.students-subheader {
  text-align: center;
  color: #666;
  font-size: 18px;
}

.students-filter-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.students-filter-label {
  font-size: 16px;
  margin-right: 10px;
}

.students-filter-select {
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.students-loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.students-risk-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.students-table-header {
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.students-table-header:hover {
  background-color: #ddd;
}

.students-row:nth-child(even) {
  background-color: #f9f9f9;
}

.students-cell {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.students-cell:nth-child(1) {
  font-weight: bold;
}

.students-cell {
  transition: background-color 0.3s;
}

.students-cell:hover {
  background-color: #f5f5f5;
}
