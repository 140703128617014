#inputDate {
  height: 50px;
  width: 150px;
  margin: 0 15px;
  border: solid rgb(89, 89, 170);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
#selectGradeClass, #selectGradeClass2{
  height: 30px;
  width: 150px;
  margin: 0 15px;
  border: solid rgb(165, 165, 244);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  align-self: flex-start;
}

.label {
  margin-right: 150px;
  font-size: large;
  font-weight: bold;
}
.label1 {
  margin-left: 20px;
}
.containerPec {
  text-align: center;
}
.Pbtn {
  padding: 15px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: rgb(89, 89, 170);
  color: white;
  cursor: pointer;
}
.filters {
  margin: 15px 0;
  display: flex;
}
.redAlarm {
  background-color: rgb(210, 7, 7);
  color: azure;
}
.orangeAlarm {
  background-color: rgb(234, 213, 90);
  /* color:; */
}
.nav {
  display: flex;
  justify-content: space-evenly;
  background-color: blue;
  color: white;
  height: 10vh;
  align-items: center;
}
.navTab {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
}
.navTabActive {
  font-weight: bold;
  font-size: 20px;
  background-color: white;
  padding: 10vh;
  color: blue;
  cursor: pointer;
  user-select: none;
}
.btn {
  background-color:#2171b5;
  color: white;
    padding: 9px;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  cursor: pointer;
  user-select: none;
  margin-left: 8vw;
}
.btndelete {
  background-color:red;
  color: white;
  padding: 9px;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  cursor: pointer;
  user-select: none;
  margin-left: 8vw;
}
.badge {
  padding: 15px 10px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  width: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.absentBadge {
  background-color: red;
  color: white;
}
.lateBadge {
  background-color: orange;
  color: black;
}
.containerPortal li {
  list-style: none;
}
.containerPortal {
  box-shadow: 10px 10px 10px 10px;
  width: 80%;
  text-align: center;
}

#actTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin: 2vw 2vw 1vw 2vw;
}
#actTable td,
#actTable th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
#actTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#actTable tr:hover {
  background-color: #ddd;
}

#actTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: blue;
  color: white;
}
.formInput {
  height: 50vh;
  width: 50vw;
  margin: 0 15px;
  border: solid rgb(89, 89, 170);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media print {
  .noPrint {
    visibility: hidden;
  }
}
.timetable th,
td {
  padding: 20px;
  border: solid black;
}
.timetable {
  border-collapse: collapse;
}
.cellClass{
  font-size: small;
}
.cellClasshead{
  font-weight: bold;
}