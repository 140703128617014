.portal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0.5);
  overflow-y: scroll;
  /* opacity: 0.7; */
}
.portal {
  padding: 10px;
  max-width: 60%;
  margin: 10vh auto;
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  opacity: 1;
}
