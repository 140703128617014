/* Updated Modal and Content Styles */
.modal-content {
    max-height: 80vh; /* 80% of viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
}

.targets-table {
    width: 100%; 
    border-collapse: collapse;
}

.targets-table th, .targets-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

.targets-table thead {
    background-color: #f2f2f2;
}

.targets-table tbody tr:hover {
    background-color: #f9f9f9;
}

/* Set modal width and height to 80% of the viewport */
.modal-box {
    width: 80vw; /* 80% of the viewport width */
    max-height: 80vh; /* 80% of the viewport height */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the modal */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
}

