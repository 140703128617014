.lesson-observations-wrapper {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.lesson-observations-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  height: 90%;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.slicer {
  width: 250px;
  background-color: #f9f9f9;
  border-right: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
}

.slicer-item {
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  color: #000;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  list-style: none;
}

.slicer-item.active {
  background-color: #007bff;
  color: #fff;
}

.slicer-item:hover {
  background-color: #e0e0e0;
}

.dynamic-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.observation-details {
  max-width: 800px;
  margin: 0 auto;
}

.input-group {
  margin-bottom: 15px;
}

.judgment-result {
  margin-top: 15px;
}

@media (max-width: 480px) {
  .lesson-observations-container {
    flex-direction: column;
  }

  .slicer {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
.top_title {
  font-weight: bold;
  color: darkblue;
  font-size: large;
  margin-left: 30%;
}
