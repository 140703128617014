/* Base styles for all screen sizes */
.navbar {
  background-color: #333;
  padding: 10px;
  min-width: 80vw;
}

.navbar-tab {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  padding: 8px 12px;
}

.navbar-tab.active {
  font-weight: bold;
  background-color: #666;
}

.clear {
  clear: both;
}

.teacher-dashboard,
.school-overall {
  background-color: #f0f0f0;
  padding: 20px;
}

.teacher-dashboard h2,
.school-overall h2 {
  color: #333;
}

.teacher-dashboard p,
.school-overall p {
  color: #666;
}

/* Media queries for different screen sizes */
@media screen and (max-width: 768px) {
  /* Styles for smaller screens */
  .navbar-tab {
    font-size: 14px;
    padding: 6px 10px;
  }

  .teacher-dashboard,
  .school-overall {
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  /* Styles for even smaller screens */
  .navbar {
    padding: 5px;
  }

  .navbar-tab {
    font-size: 12px;
    margin-right: 5px;
    padding: 4px 8px;
  }

  .teacher-dashboard,
  .school-overall {
    padding: 5px;
  }
}
