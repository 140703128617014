.attendance-dashboard {
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center; /* Centers all elements */
  }
  
  .date-picker {
    margin-bottom: 20px;
    display: flex;
    justify-content: center; /* Centers the date picker */
  }
  
  .charts-container {
    display: flex;
    flex-direction: column; /* For mobile screens */
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    /* For tablet and larger screens */
    .charts-container {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .attendance-chart {
    width: 80%; /* Give more space to the bar chart */
    max-width: 600px; /* Set a maximum width */
    margin-bottom: 20px; /* Add space below for mobile screens */
  }
  
  .doughnut-chart {
    width: 60%; /* Doughnut chart takes less space */
    max-width: 300px; /* Set a maximum width */
    margin-left: 20px;
  }
  
  @media (min-width: 1024px) {
    .attendance-chart {
      width: 65%; /* Bar chart takes more space on large screens */
    }
  
    .doughnut-chart {
      width: 30%; /* Doughnut chart takes less space on large screens */
    }
  }
  