#examtimetable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
}
#examtimetable td,
#examtimetable th {
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
}
#examtimetable td {
  font-size: small;
  text-align: left;
}

/* #examtimetable tr:nth-child(even) {

} */

/* #examtimetable tr:hover {
  background-color: #ddd;
} */

#examtimetable th {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #287ff2;
  color: white;
}
.greenbtn {
  background-color: green;
  color: white;
  font-weight: bold;
  margin-right: 2px;
  border: solid green;
}
.orangebtn {
  background-color: orange;
  font-weight: bold;
  margin-right: 2px;
  border: solid orange;
}
.lightgreenbtn {
  background-color: lightgreen;
  font-weight: bold;
  margin-right: 2px;
  border: solid lightgreen;
}
.redbtn {
  background-color: red;
  font-weight: bold;
  color: white;
  margin-right: 2px;
  border: solid red;
}

.classifierbtn {
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  color: darkblue;
  font-weight: bold;
  text-decoration-line: underline;
  -moz-text-decoration-line: underline;
}
