/* Container for the entire table and graph */
.assessment-results-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title Styling */
  .assessment-results-container h3 {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  /* Styling for the "Last Update on" text */
  .assessment-results-container p {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    color: #333;
  }
  
  /* Row styling for filters */
  .filter-row {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  /* Column styling for each filter input */
  .filter-column {
    flex: 1;
    padding: 0 10px;
  }
  
  /* Graph container styling */
  .graph-container {
    margin-bottom: 30px;
  }
  
  .recharts-wrapper {
    width: 100%;
    height: 150px !important;
  }
  
  /* Table styling */
  .ant-table {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    font-weight: 600;
    text-align: center;
  }
  
  .ant-table-tbody > tr > td {
    text-align: center;
  }
  
  .ant-pagination {
    margin-right: 20px;
  }
  
  /* Select input styling */
  .ant-select {
    width: 100%;
  }
  
  .ant-select-selection {
    height: 40px;
  }
  
  /* Input filter styling */
  .ant-input {
    height: 40px;
  }
  
  /* Responsive container */
  @media (max-width: 768px) {
    .filter-row {
      flex-direction: column;
    }
  
    .filter-column {
      padding: 10px 0;
    }
  }
  