.creatortable td {
  padding: 0px;
  font-size: large;
  width: 10%;
  padding: 5px;
  font-weight: lighter;
  border: white inset;


}

.creatortable th {
  padding: 0px;
  font-size: small;
  font-weight: lighter;
  border-collapse: collapse;
}
.creatortable {
  border-collapse: collapse;
}
.form-container {
  background-color: #f0f4f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-form {
  display: flex;
  flex-direction: column;
}

.input-group-student,
.input-group-attendance {
  margin-bottom: 15px;
}

.label-student,
.label-attendance {
  display: block;
  margin-bottom: 5px;
  color: #007bff;
}

.input-student,
.input-attendance {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}
