.successMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500%;
}

.message {
  /* border: solid black; */
  padding: 35px;
  background-color: antiquewhite;
}
