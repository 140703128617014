.teachers-intervention {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.left-column {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #007bff;
}

.middle-column {
  flex: 3; /* Reduced space for the middle column */
  padding: 20px;
  border-right: 1px solid #007bff;
}

.right-column {
  flex: 3; /* Increased space for the right column */
  overflow-y: auto;
  padding: 20px;
}

.teachers-list,
.students-list {
  list-style-type: none;
  padding: 0;
}

.teachers-list li,
.students-list li {
  padding: 10px;
  cursor: pointer;
  color: #007bff;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.teachers-list li.active,
.teachers-list li:hover,
.middle-column tbody tr.active,
.middle-column tbody tr:hover {
  background-color: #007bff;
  color: white;
}

.timetable table,
.students-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.timetable th,
.timetable td,
.students-table th,
.students-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.timetable th,
.students-table th {
  background-color: #f2f2f2;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.session-details {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-top: 20px;
}

.modal-boxInervention {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  padding: 20px;
  border: 2px solid #007bff;
  box-shadow: 24;
  border-radius: 10px;
}

.close-button {
  float: right;
  cursor: pointer;
  margin-bottom: 20px;
  color: #007bff;
}

.attendance-present {
  background-color: #4caf50; /* Green */
  color: white;
  cursor: pointer;
  padding: 8px;
  width: 100%;
}

.attendance-absent {
  background-color: #f44336; /* Red */
  color: white;
  cursor: pointer;
  padding: 8px;
  width: 100%;
}

.attendance-late {
  background-color: #ff9800; /* Orange */
  color: white;
  padding: 8px;
  width: 100%;
}
