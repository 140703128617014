/* Base styles for all screen sizes */
.component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.dropdowns {
  margin-bottom: 20px;
}

.dropdown {
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(235, 217, 185);
  color: black;
  font-size: 14px;
}

.ratio-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.ratio-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ratio-label {
  color: black;
  font-size: 14px;
}

.ratio-value {
  font-size: 20px;
  font-weight: bold;
}

.percentage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.percentage-label {
  color: black;
  font-size: 14px;
}

.percentage-value {
  font-size: 24px;
  font-weight: bold;
}

.table-container {
  width: 100%;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

 .teacherDashboard  input[type="text"] {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(235, 217, 185);
  color: black;
  font-size: 14px;
}

.student-table {
  width: 100%;
  border-collapse: collapse;
}

.student-table th,
.student-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid black;
}

.student-table th {
  background-color: black;
  color: white;
  cursor: pointer;
}

.student-table td:last-child {
  text-align: center;
}

.actions {
  display: flex;
  justify-content: center;
}

.action-button {
  padding: 5px;
  margin: 0 2px;
  border: none;
  background-color: rgb(235, 217, 185);
  color: black;
  font-size: 14px;
  cursor: pointer;
}

/* Material Icons styles */
.material-icons {
  font-size: 18px;
}

.notMet {
  background-color: red;
  font-weight: bold;
}

.metOrExceed {
  background-color: rgb(47, 238, 255);
  font-weight: bold;
}

.Exceed {
  background-color: greenyellow;
  font-weight: bold;
}

.closeToMeet {
  background-color: orange;
  font-weight: bold;
}

.sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  transition: background-color 0.3s;
}

.sort-button:hover {
  background-color: #e0e0e0;
}

.sort-arrow {
  margin-left: 6px;
  font-size: 12px;
}

/* Media queries for different screen sizes */
@media screen and (max-width: 768px) {
  /* Styles for smaller screens */
  .component-container {
    width: 90%;
  }

  .dropdown {
    font-size: 12px;
    padding: 8px;
    margin-right: 8px;
  }

  .ratio-value {
    font-size: 18px;
  }

  .percentage-value {
    font-size: 20px;
  }

   .teacherDashboard  input[type="text"] {
    font-size: 12px;
    padding: 8px;
  }

  .action-button {
    font-size: 12px;
    padding: 3px;
  }

  .material-icons {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  /* Styles for even smaller screens */
  .component-container {
    width: 100%;
  }

  .dropdown {
    font-size: 10px;
    padding: 6px;
    margin-right: 6px;
  }

  .ratio-value {
    font-size: 16px;
  }

  .percentage-value {
    font-size: 18px;
  }

  .teacherDashboard  input[type="text"] {
    font-size: 10px;
    padding: 6px;
  }

  .action-button {
    font-size: 10px;
    padding: 3px;
  }

  .material-icons {
    font-size: 14px;
  }
}
