.attButton {
  border: outset;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}
.attButtonDisabled {
  pointer-events: none;
  border: outset;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}

.plum {
  background-color: plum;
}
.aquamarine {
  background-color: aquamarine;
}
.yellow {
  background-color: yellow;
  color: black;
}
.statsTable {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.statsTable td,
.statsTable th {
  border: 1px solid;
}

.statsTable table {
  width: 95%;
  border-collapse: collapse;
}
