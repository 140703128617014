.form-container {
  width: 100%;
    /* margin: 20px; */
    /* padding: 20px; */
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: -30px;
  }
  
  .form-title {
    margin-bottom: 20px;  
  }
  
  .student-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-field {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button {
    padding: 10px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  