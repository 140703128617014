.tabs {
  display: flex;
  width: 80vw;
  border-bottom: solid darkblue;
}
.tab {
  margin-right: 5vw;
  padding: 2%;
  margin-bottom: -3%;
}
.tabActive {
  border-top-left-radius: 25%;
  border-top-right-radius: 25%;
  margin-right: 5vw;
  padding: 1%;
  background-color: rgb(21, 37, 130);
  color: white;
  font-weight: bolder;
  font-size: 20px;
  border-style: outset;
}
.marks {
  width: 5.5vw;
  border: none;
}
.marks:focus {
  outline: none;
}

.tdborder {
  border: none;
}
.totalmark {
  width: 5vw;
}

.totalmark::-webkit-outer-spin-button,
.totalmark::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.marks::-webkit-outer-spin-button,
.marks::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.savebtn {
  display: flex;

  border: solid white;
  background-color: white;
  cursor: pointer;
  color: darkblue;
  font-weight: bold;
  font-size: large;
  justify-items: center;
  align-items: center;
}
