
  .student-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 18px;
    text-align: left;
  }
  
  .student-table th,
  .student-table td {
    border: 1px solid #dddddd;
    padding: 8px;
  }
  
  .student-table th {
    background-color:black;
  }
  
  .student-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  