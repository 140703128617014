.dashboard-container {
  padding: 20px;
}

.date-picker {
  margin-bottom: 20px;
}

.chart-container-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensures responsiveness */
}

.chart-container {
  width: 48%; /* Adjust width to fit two charts side by side */
  margin-bottom: 20px;
}

.attendance-table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th {
  cursor: pointer;
  /* background-color: #f2f2f2; */
}

.attendance-table th, .attendance-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* .attendance-table tr:hover {
  background-color: #f5f5f5;
} */
.absent-row {
  background-color: lightcoral;
}

.late-row {
  background-color: orange;
}

.attendance-percentage-container {
  margin: 20px 0;
  text-align: center;
}

.attendance-percentage {
  font-size: 24px;
  font-weight: bold;
  color: red; /* Green for positive reinforcement */
}

.attendance-filter {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.attendance-filter label {
  margin-right: 10px;
}

.attendance-filter select {
  padding: 5px;
  font-size: 16px;
}

/* Media query to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .chart-container {
      width: 100%; /* Make charts stack vertically on smaller screens */
  }
}
