.attendanceTracker {
  border-collapse: collapse;
  font-size: small;
  border: solid white 0.5px;
  margin-bottom: 25px;
  /* min-width: 150%; */
}
.attendanceTracker td,
th {
  margin-top: 10px;
  border-collapse: collapse;
  font-size: small;
  border: solid white 0.5px;
  padding: 5px;
}
.attendanceTracker th {
  background-color: rgb(241, 217, 157);
}
.attendanceTracker td:nth-child(1) {
  background-color: rgb(241, 217, 157);
  border: solid white 0.5px;
}
.greenBackGround {
  background-color: rgb(154, 229, 154);
}
.redBackGround {
  background-color: rgb(237, 157, 157);
}

.marksEntryTracker {
  border-collapse: collapse;
  /* font-size: small; */
  border: solid white 0.5px;
}
.marksEntryTracker td,
th {
  margin-top: 10px;
  border-collapse: collapse;
  border: solid white 0.5px;
  padding: 2px;
}
.marksEntryTracker th {
  background-color: rgba(36, 6, 62, 0.225);
}
.marksEntryTracker td:nth-child(1) {
  background-color: rgba(36, 6, 62, 0.225);
  border: solid white 0.5px;
}
