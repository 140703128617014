.inter-student-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.search-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search-bar input, .search-bar select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 48%;
}

.student-details {
    margin-bottom: 20px;
}

.student-details p {
    margin: 5px 0;
    font-size: 16px;
}

.timetable h4 {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
}

.timetable ul {
    list-style-type: none;
    padding: 0;
}

.timetable ul li {
    padding: 8px 0;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
}

.class-view table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.class-view th, .class-view td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.class-view th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.class-view tr:nth-child(even) {
    background-color: #f9f9f9;
}

.class-view tr:hover {
    background-color: #f1f1f1;
}
