.start-observation-wrapper {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.start-observation-container h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.start-observation-container .container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.start-observation-container form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 20px;
}

.start-observation-container form > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  grid-column: span 2;
}

.start-observation-container label {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.start-observation-container input, .start-observation-container select, .start-observation-container button {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.start-observation-container input:focus, .start-observation-container select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.start-observation-container button {
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  grid-column: span 2;
  font-size: 1rem;
}

.start-observation-container button:hover {
  background-color: #218838;
}

.start-observation-container button:active {
  background-color: #1e7e34;
}

.start-observation-container select {
  appearance: none;
  background-color: #fff;
  cursor: pointer;
}

.start-observation-container select:hover {
  border-color: #007bff;
}

@media (max-width: 480px) {
  .start-observation-container .container {
    width: 90%;
  }

  .start-observation-container h2 {
    font-size: 1.5rem;
  }

  .start-observation-container form {
    grid-template-columns: 1fr;
  }

  .start-observation-container button {
    grid-column: span 1;
  }
}
