.swapsButton {
  border: outset;
  user-select: none;
  background-color: aqua;
  cursor: pointer;
}
.swapsButton:active {
  border: inset;
}
.coverageTable td,
th {
  border: solid white;
}
.greenCell {
  background-color: rgb(150, 238, 150);
  color: white;
}
.redCell {
  background-color: rgb(173, 71, 71);
  color: white;
}
.orangeCell {
  background-color: orange;
  color: black;
}
