.groups-page {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.left-column,
.middle-column,
.right-column {
  border: 1px solid black;
  padding: 10px;
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.middle-column {
  flex: 2;
  padding: 10px;
  overflow-y: auto;
}

.right-column {
  flex: 2;
  padding: 10px;
  overflow-y: auto;
}

.filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.filter-by-grade,
.filter-by-date {
  margin-bottom: 10px;
}

.groups-list {
  overflow-y: auto;
}

.group-box {
  border: 2px solid #007bff; /* Blue border to resemble a button */
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 5px; /* Rounded corners */
  text-align: left;
  background-color: #f8f9fa; /* Light grey background */
  color: #0e0e0e; /* Blue text */
  font-weight: bold;
}

.group-box:hover {
  background-color: #007bff; /* Blue background on hover */
  color: #fff; /* White text on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow to lift the button */
}

.group-box.active {
  background-color: #0056b3; /* Dark blue background for active state */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow */
  border-color: #0056b3; /* Dark blue border for active state */
}
.group-box.active p {
  color: white; /* White text */
}

.students-list table,
.group-schedule table {
  width: 100%;
  border-collapse: collapse;
}

.students-list th,
.group-schedule th,
.students-list td,
.group-schedule td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.students-list th,
.group-schedule th {
  background-color: #f2f2f2;
}
