.data-entry-form {
    background-color: lightblue;
    padding: 20px;
    border-radius: 10px;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-label {
    display: block;
    font-weight: bold;
    color: darkblue;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid blue;
  }
  
  .submit-button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: darkblue;
  }
  