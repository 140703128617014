@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");
.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.nav li {
  float: right;
}

.nav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.nav li a:hover:not(.active) {
  background-color: #111;
}
h3 {
  user-select: none;
  text-align: center;
  font-size: xx-large;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: darkblue;
}
h2 {
  text-align: center;
  font-size: large;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: darkslateblue;
}
.active {
  background-color: #4caf50;
}

.main-font {
  font-family: Arial, Georgia, "Times New Roman", Times, serif, Helvetica,
    sans-serif;
}
th {
  padding: 0.55rem;
}
td {
  padding: 8px;
}
.card:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
.header {
  display: flex;
  justify-content: center;
}
/* @media screen and (min-width: 600px) {
  .card2 {
    margin-top: 15px;
  }
} */
.newSignin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
