.heatmap-table {
  width: 100%;
  border-collapse: collapse;
}

.heatmap-table th,
.heatmap-table td {
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid black;
}

.heatmap-table th {
  background-color: black;
  color: white;
  padding: 10px;
}

.heat-cell {
  font-weight: bold;
}

.heat-green {
  background-color: green;
  color: white;
}

.heat-red-dark {
  background-color: #8b0000;
  color: white;
}

.heat-red-light {
  background-color: #ff5555;
  color: white;
}
.level_1 {
  background-color: #e34f4f;
}
.level_2 {
  background-color: #ea7b7b;
}
.level_3 {
  background-color: #fae0e0;
}
.level_4 {
  background-color:greenyellow;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .heatmap-table th,
  .heatmap-table td {
    font-size: 12px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .heatmap-table th,
  .heatmap-table td {
    font-size: 10px;
    padding: 6px;
  }
}
