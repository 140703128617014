.student-info {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .info-title {
    font-size: 2rem;
    color: #ec1f4a;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .info-label {
    font-weight: bold;
    color: #6b6bff;
  }
  
  .info-value {
    color: #333333;
    margin-left: 10px;
  }
  
  .info-link {
    color: #66c0f4;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .info-link:hover {
    color: #55a7d8;
  }
  
  .info-label,
  .info-value {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .password-wrapper {
    display: flex;
    align-items: center;
  }
  
  .password-hide {
    font-size: 0.9rem;
    margin-left: 10px;
    color: #888888;
    cursor: pointer;
  }
  
  .password-hide:hover {
    color: #555555;
  }
  