/* ReportCard.css */

.report-card-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f4faff;
    border: 2px solid #aacbff;
    border-radius: 10px;
}

.student-info {
    background-color: #e0efff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.student-info p {
    font-size: 16px;
    color: #333;
}

h1 {
    color: #0056b3;
    text-align: center;
}

.tableblocked {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden;
}

.thblocked, .tdblocked {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #aacbff;
}

.thblocked {
    background-color: #007bff;
    color: #ffffff;
}

tr.blocked:nth-child(even) {
    background-color: #f2f2f2;
}

