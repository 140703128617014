.UploadForm {
    background-color: #c7e9fb;
    border: 2px solid #2171b5;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type='file'] {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  select {
    margin-left: 10px;
  }
  