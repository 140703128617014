.TimetableCreator {
  background-color: #cce5ff;
  border: 2px solid #2171b5;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  font-size: smaller;
}

.selected-dataset {
  border-collapse: collapse;
  margin-top: 10px;
}

.selected-dataset th,
.selected-dataset td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.selected-dataset th {
  background-color: #2171b5;
  color: #fff;
}
