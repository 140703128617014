.form-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  margin-top: 20px;
}

h3 {
  margin-top: 15px;
}

label {
  display: block;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 100px;
  resize: vertical;
}
input[type="checkbox"] {
  margin-right: 5px;
}

button[type="submit"] {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}
#closeBtn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff4949;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 20px;
}
button[type="submit"]:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: 5px;
}
.feedbackForm textarea {
  max-height: 75px;
  max-width: 200px;
  margin-left: 30px;
}

.feedbackForm h4 {
  display: inline-block;
  padding: 0.25em 0;
  background: lightblue;
  /* color: #ffffff; */
}
.feedbackForm h2 {
  /* display: inline-block; */
  /* padding: 0.25em 0; */
  background: #7cccec;
  /* color: #ffffff; */
}
/* #mutliselect{
    max-width: 200px;
} */
.btnContainer {
  text-align: center;
}
.feedbackFormTable {
  font-size: small;
}
.feedbackFormTable table {
  width: 100%;
  border-collapse: collapse;
}

.feedbackFormTabl th {
  background: #f2f2f2;
  font-weight: bold;
  /* padding: 8px; */
  border: 1px solid #ccc;
}

.feedbackFormTabl td {
  /* padding: 8px; */
  border: 1px solid #ccc;
}
.studentDetails {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  color: white;
  background-color: darkblue;
  font-weight: bold;
  padding: 5px;
}

.feedFormValues {
  color: #7770e0;
  font-weight: bolder;
  background-color: lightgoldenrodyellow;
 font-style: italic;
font-size: large;
}
