.swapsTableNew{
  border: solid black;
}
.tdForSwaps {
  background-color: aqua;
  border: outset;
}
.tdForSwaps:active {
  border: inset;
}
