.select {
  margin: 5px 5px;
  height: 35px;
  border-color: cornflowerblue;
  border-radius: 5px;
}
.container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.supercontainer {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.csv {
  visibility: hidden;
}
.downloadButton {
  text-align: center;
  margin-top: 20px;
}
#markstable,
td {
  padding: 20px;
}
#closeButtonDiv {
  width: 104%;
  display: flex;
  justify-content: flex-end;
  background-color: cornflowerblue;
}
#btnClose {
  padding: 3px 5px;
  background-color: red;
  cursor: pointer;
  color: white;
}
.marksTableContainer {
  display: flex;
  width: 90vw;
  justify-content: center;
}
.coloredMark {
  background-color: pink;
}
.inputMarks {
  width: 50px;
}
#markstable {
  margin-left: -600px;
}
.redcell{
  
  background-color: pink;
}
.orangeCell{

  background-color:orange
}
