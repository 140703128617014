.reportHeader {
  border: solid black;
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
}
.textReportHeader {
  /* width: 30%; */
  font-size: 15px;
  font-weight: bold;
  color: navy;
  margin-top: 5px;
}
