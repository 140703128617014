/* Container that holds both the slicer and table display */
.exam-schedule-container {
    display: flex;
    width: 100%;
    padding: 20px;
  }
  
  /* Slicer section on the left */
  .exam-schedule-slicer {
    width: 80%;
    background-color: #f1f1f1;
    padding: 10px;
    border-right: 1px solid #ddd;
  }
  
  .exam-schedule-title {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }
  
  .exam-schedule-list {
    list-style-type: none;
    padding: 0;
  }
  
  .exam-schedule-list-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 16px;
    color: #333;
  }
  
  .exam-schedule-list-item:hover {
    background-color: #007bff;
    color: white;
  }
  
  .exam-schedule-list-item.active {
    background-color: #0056b3;
    color: white;
    font-weight: bold;
  }
  
  /* Table section on the right */
  .exam-schedule-table-wrapper {
    width: 75%;
    padding-left: 20px;
  }
  
  .exam-schedule-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-family: Arial, sans-serif;
  }
  
  .exam-schedule-table thead th {
    background-color: #007bff;
    color: white;
    padding: 12px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ddd;
  }
  
  .exam-schedule-table tbody td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .exam-schedule-class-column {
    background-color: #f1f1f1;
    font-weight: bold;
  }
  
  .exam-schedule-subject-column {
    background-color: #ffffff;
  }
  
  /* Placeholder for when no date is selected */
  .exam-schedule-placeholder {
    font-size: 16px;
    color: #555;
  }
  
  /* Fix table row height */
  .exam-schedule-table tbody tr {
    height: 45px;
  }
  
  .exam-schedule-table-wrapper p {
    font-size: 16px;
    color: #555;
  }
  