.pods-intervention {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  /* .select {
    width: 48%;
    padding: 10px;
    font-size: 16px;
  } */
  
  .right-column {
    overflow-y: auto;
  }
  
  .students-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .students-table th,
  .students-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .students-table th {
    background-color: #f2f2f2;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  