.navbarIntervention {
    background-color: #f8f9fa;
    border-bottom: 2px solid #007bff;
    padding: 10px 20px;
  }
  
  .navbarIntervention ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }
  
  .navbarIntervention li {
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .navbarIntervention li.active {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }
  
  .navbarIntervention li:hover {
    background-color: #0056b3;
    color: white;
    border-radius: 5px;
  }
  
  .content {
    padding: 10px;
  }
  