.inter-timetable-container {
    width: 100%;
    margin: 20px;
}

.week-selector {
    margin-bottom: 20px;
    text-align: left;
}

.inter-timetable {
    width: 100%;
    border-collapse: collapse;
}

.inter-timetable th,
.inter-timetable td {
    border: 1px solid black;
    padding: 10px;
    text-align: center;
}

.inter-timetable th {
    background-color: #f2f2f2;
}

.inter-timetable td {
    background-color: #fff;
}
