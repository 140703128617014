/* Container styling */
.BehaviourReporting {
    padding: 20px;
  }
  
  /* Search Container */
  .search-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-container input, .search-container select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .search-container button {
    font-size: 16px;
  }
  
  /* Table Styling */
  .table-container {
    overflow-x: auto;
  }
  
  .student-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .student-table th, .student-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .student-table th {
    font-weight: bold;
    background-color: #f2f2f2;
    color: #000;
    cursor: pointer;
  }
  
  /* Header content styling for text and icon alignment */
  .student-table th .header-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px; /* Space between text and icon */
    width: auto;
    color: black;
  }
  
  .student-table th svg {
    font-size: 16px; /* Icon size */
    color: black;
  }
  
  /* Hover effect for rows */
  .student-table tr:hover {
    background-color: #f9f9f9;
  }
  
  /* Modal Content Styling */
  .modal-content {
    background-color: white;
    width: 70%;
    height: 70%;
    position: absolute;
    top: 15%;
    left: 15%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
  }
  
  /* Offense icons container in modal */
  .offense-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .offense-icons IconButton {
    font-size: 24px;
  }
  
  /* Icon Button Styling */
  .icon-button {
    color: #3f51b5; /* Customize icon color */
  }
  
  .icon-button:hover {
    color: #303f9f;
  }
  
  /* Tooltip styling for icons */
  .MuiTooltip-tooltip {
    font-size: 20px;
  }
  
  /* Sorting icon */
  .student-table th .MuiSvgIcon-root {
    font-size: 16px;
  }
  .stOffenseReport{
   margin-left: 50%;
   color: #303f9f;
   font-size: large;
  }
  .offense-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .level-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .level-box h4 {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .offense-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .level-one {
    background-color: #e3f2fd;
  }
  
  .level-two {
    background-color: #fff3e0;
  }
  
  .level-three {
    background-color: #ffebee;
  }
  
  .level-four {
    background-color: #ede7f6;
  }
  